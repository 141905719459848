@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-primary);
}

body {
  font-family: var(--font-secondary);
}
input, select{
  font-size:16px;
}
.ripple-effect {
  position: relative;
  overflow: hidden;
}

/* Add a pseudo-element that will represent the ripple */
.ripple-effect::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-radius: 50%;
  opacity: 0.5;
  background-color: white;
  pointer-events: none; /* make the ripple non-clickable */
  transition: all 0.5s ease-out; /* add a transition to the ripple */
}

/* Add a hover or focus effect to the button */
.button:hover .ripple-effect::after,
.button:focus .ripple-effect::after {
  /* define the size and position of the ripple */
  width: 200%;
  height: 200%;
  top: 0%;
  left: 0%;
  opacity: 1;
}
.map-component {
  height: auto;
  overflow: hidden;
  position: relative;
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
}
.map-component div:not([class*=" cw"]){
  width:100%;
}

.rdrDefinedRangesWrapper {
  display: none;
}
.respm > div > div {
  flex-direction:column;
  overflow:hidden;
}
.inline-size{
  container-type:inline-size;
}
.translate-right{
  transform:translateX(calc(100cqw - 100%))
}
@media (max-width:768px){
  .respm > div > div {
  overflow:scroll;
  flex-direction:row !important;
  max-height:300px;
}
.respm > div > div > img {
  object-fit:cover;
}
}
